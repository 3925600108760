<template>
  <div class="space-y-4">
    <asom-modal
      title="Create Shift End Summary"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <form>
        <div class="gap-20 py-5 bg-white rounded-lg overflow-hidden  flex">
          <div v-for="(item, index) in psmBalanceModels" :key="index">
            <asom-alert v-if="error" variant="error" :error-message="error" />
            <asom-form-field
              label="Total Cash in PSM ($)"
              required
              :state="inputStates(`formData.cashTotal[${index}]`)"
              error="Total Cash is required"
            >
              <asom-input-text
                type="number"
                min="0"
                v-model="formData.cashTotal[index]"
                :state="inputStates(`formData.cashTotal[${index}]`)"
              />
            </asom-form-field>
            
            <div class="pt-8" >
              <p class="pb-2 font-sans font-semibold text-gray-500">
                {{ item.stationPSMName }}
              </p>
              <div
                class="max-w-sm relative rounded-lg bg-gray-100 px-6 py-5 shadow items-center"
              >
                <div class="p-2 h-10 w-10 bg-button rounded-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="pt-4">
                  <p class="font-sans font-bold text-gray-500 text-3xl">
                    {{ formatCurrency(parseFloat(this.formData.cashTotal[index])) }}
                  </p>
                  <p class="font-sans font-semibold text-gray-500">
                    in Current Shift
                  </p>
                </div>
                <div class="pt-6">
                  <div>
                    <span class="font-sans text-button">{{
                      formatCurrency(item.psmInFlowAmount)
                    }}</span>
                    <span class="font-sans text-gray-500"> Float Adjustment (+)</span>
                  </div>
                  <div>
                    <span class="font-sans text-button">{{
                      formatCurrency(item.psmOutFlowAmount)
                    }}</span>
                    <span class="font-sans text-gray-500"> Float Adjustment (-)</span>
                  </div>
                  <div>
                    <span class="font-sans text-button">{{
                      formatCurrency(item.currentBalance)
                    }}</span>
                    <span class="font-sans text-gray-500"> Net Float Adjustment</span>
                  </div>
                </div>
              </div>
            </div>

            <asom-form-field
              class="col-span-2 pt-8"
              label="Supporting documents"
              required
              :state="inputStates(`formData.files[${index}]`)"
              error="Supporting documents are required"
            >
              <asom-upload
                v-model="formData.files[index]"
                :category="attachmentCategories.CASH_MANAGEMENT"
                :state="inputStates(`formData.files[${index}]`)"
              />
            </asom-form-field>

          </div>
        </div>
  
        <div class="col-span-2 flex justify-end space-x-4 pt-4">
          <asom-button
            text="Back"
            variant="secondary"
            @click="$router.push({ name: 'PSM Manual Record Main Page' })"
          />
          <asom-button
            text="Declare Cash Bag"
            variant="secondary"
            @click="$router.push({ name: 'Cash Declaration Form Create' })"
          />
          <asom-button
            text="Confirm Shift End"
            @click="submitClicked"
            :loading="isSubmitting"
          />
        </div>
      </form>
    </asom-card>
    <div class="pt-6">
      <div>PSM Manual Record</div>
      <div class="p-4">Current Shift: {{ shift }}</div>
      <asom-client-table
        :columns="[
          'transactionNo',
          'createdBy',
          'dateCreated',
          'timeCreated',
          'line',
          'station',
          'rosterDws',
        ]"
        :data="getTableData"
        :sortableColumns="[
          'transactionNo',
          'createdBy',
          'dateCreated',
          'timeCreated',
          'line',
          'station',
          'rosterDws',
        ]"
        :filterable="false"
        :pagination="false"
      >
        <template v-slot:dateCreated="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.data) }}</span>
        </template>
        <template v-slot:timeCreated="scopedSlots">
          <span>{{
            displayUtcTime(get(scopedSlots.rowData, "dateCreated"))
          }}</span>
        </template>
        <template v-slot:header_transactionNo>Transaction No</template>
        <template v-slot:transactionNo="scopedSlots">
          <div class="flex flex-row">
            <div class="flex pl-2">
              <asom-link @click="navigateToView(scopedSlots.rowData)"
                >{{ scopedSlots.data }}
              </asom-link>
            </div>
          </div>
        </template>
      </asom-client-table>
    </div>
  </div>
</template>

<script>
import { required,
  //  minValue
  } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import get from "lodash.get";
import sortBy from "lodash.sortby";
import {
  createShiftEndSummary,
  getPSCShiftInfo,
} from "@/services/cashManagement.service";
import { formatCurrency, 
  // add, subtract
 } from "../../../../helpers/numbers";
import { displayUtcDate, displayUtcTime } from "@/helpers/dateTimeHelpers";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  name: "MRShiftEndSummary",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    // let psmSum = 0;
    // if (this.$route.query) {
    //   psmSum = Number.parseInt(this.$route.query.psmSum);
    //   psmSum = Number.isNaN(psmSum) ? 0 : psmSum;
    // }
    return {
      showModal: false,
      psmLogs: [],
      activityLogs: [],
      formData: {
        cashTotal: [],
        files: [],
      },
      psmManual: 0,
      psmInFlow: 0,
      psmOutFlow: 0,
      psmBalanceModels: [],

      isSubmitting: false,
      error: null,
    };
  },
  validations() {
    return {
      formData: {
        cashTotal: { required },
        files: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      shift: "currentRoster/currentRosterShift",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
    }),
    getTableData() {
      let logs = sortBy(this.activityLogs, "dateCreated");
      return logs.reverse();
    },
    // psmMachineAmount() {
    //   let amount = add(
    //     subtract(subtract(this.total, this.psmManual), this.previousShift),
    //     this.providedToCashbag
    //   );
    //   if (amount) {
    //     return amount;
    //   } else return 0;
    // },
    psmLogIds() {
      return this.psmLogs.map(({ transactionId }) => transactionId);
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  mounted() {
    this.getShiftInfo();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getShiftInfo();
    },
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcTime,
    formatCurrency,
    navigateToView(selectedTransaction) {
      this.$router.push({
        name: "View Manual Record Transaction",
        params: selectedTransaction,
      });
    },
    inputType(type) {
      if (type == 1) {
        return "Passenger name";
      } else if (type == 2) {
        return "CAN ID";
      } else if (type == 3) {
        return "Notebox No.";
      } else if (type == 4) {
        return "Coinbox No.";
      } else if (type == 5) {
        return "PSM Top-up";
      } else if (type == 6) {
        return "Others";
      } else {
        return "";
      }
    },
    async getShiftInfo() {
      this.isSubmitting = true;
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const response = await getPSCShiftInfo({
        pSCId: this.pscId,
      });
      if (response.success) {
        this.isSubmitting = false;
        this.psmManual = get(response.payload, "data.psmManualAmount", 0);
        this.psmInFlow = get(response.payload, "data.psmInFlowAmount", 0);
        this.psmOutFlow = get(response.payload, "data.psmOutFlowAmount", 0);

        this.psmBalanceModels = response.payload.data.psmBalanceModels || [];
        this.psmBalanceModels.forEach(() => {
          this.formData.files.push([]);
        })

        this.formData.cashTotal = this.psmBalanceModels.map(l => l.currentBalance);
        this.activityLogs = get(response.payload, "transactions", []);
        this.psmLogs = get(response.payload, "data.psmTransactions", []);
      } else {
        this.error = response.payload;
        this.isSubmitting = false;
        this.$scrollTop();
        return;
      }
    },
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.showModal = true;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const totalAll = this.formData.cashTotal.reduce(( a, r ) => {
        return parseFloat(a) + parseFloat(r)
      }, 0);

      const params = {
        psmShiftEndAmountOfVariedPSMs: this.psmBalanceModels.map((item,i) => {
          return {
            stationPSMId:  item.stationPSMId,
            psmShiftEndAmount: parseFloat(this.formData.cashTotal[i] || 0),
            attachmentIds: this.formData.files[i].map(r => r.fileId),
          }
        }),
        shiftEndSummaryType: 1,
        psmShiftEndAmount: totalAll,
        psmManualAmount: this.psmOutFlow,
        psmMachineAmount: this.psmInFlow,

        psmTransactionIds: this.psmLogIds,
        preEncodedTicketTransactionIds: [],
        cashFloatTransactionIds: [],
        accountId: this.pscId,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      }
      // console.log('提交参数-------',params);
      const result = await createShiftEndSummary(params);
      if (result.success) {
        this.isSubmitting = false;
        this.$router.push({ name: "PSM Manual Record Main Page" });
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
      this.showModal = false;
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
